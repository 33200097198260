$(document).foundation();

$('[data-tabs]').on('change.zf.tabs', function () {
      new Foundation.Equalizer($('.tabs-panel.is-active .row')).applyHeight();
      if (typeof initialise !== 'undefined' && $.isFunction(initialise)) {
            initialise();
      }
}); 

$(document).ready(function() {
	$(".title-bar").click(function() {
		$('.title-bar').foundation('toggleMenu');
	});
	$(".menu-icon").click(function() {
		$('.title-bar').foundation('toggleMenu');
	});
	
	if ($('#news-belt').length > 0) {
		$.ajax({
			type: "GET",
			url: "/ajax-api/fetch-fp-news",
			cache: false,
			success: function (html) {
				if(html=="nothing"){
					
				}else{
					if ( html.length > 0 ) {
						$( '#news-belt' ).append(html);
					}
				}
			}
		});
	}

	if ($('#news-side').length > 0) {
		$.ajax({
			type: "GET",
			url: "/ajax-api/fetch-sb-news",
			cache: false,
			success: function (html) {
				if(html=="nothing"){
					
				}else{
					if ( html.length > 0 ) {
						$( '#news-side' ).append(html);
					}
				}
			}
		});
	}

	if ($('#main-carousel').length > 0) {
		$('#main-carousel').owlCarousel({
			loop: true,
			margin: 0,
			nav: true,
			navText: ["<img src='/assets/img/arrowleft.svg' />","<img src='/assets/img/arrowright.svg' />"],
			dots: false,
			autoplay:true,
			autoplayTimeout:10000,
			autoplayHoverPause:true,
			responsive:{
				0:{ items:1 }
			}
		});
	};
	if ($('#journal-carousel').length > 0) {
		$('#journal-carousel').owlCarousel({
			loop: true,
			margin: 0,
			nav: false,
			dots: true,
			autoplay:true,
			autoplayTimeout:10000,
			autoplayHoverPause:true,
			responsive:{
				0:{ items:2 },
				768:{ items:4 },
				1025:{ items:6 },
			}
		});
	};
	if ($('#sector-carousel').length > 0) {
		$('#sector-carousel').owlCarousel({
			loop: true,
			margin: 0,
			nav: false,
			dots: true,
			autoplay:true,
			autoplayTimeout:10000,
			autoplayHoverPause:true,
			responsive:{
				0:{ items:1 },
				768:{ items:1 },
				1025:{ items:1 },
			}
		});
	};
	$( '.parallax' ).stellar({
		// Set scrolling to be in either one or both directions
		horizontalScrolling: false,
		verticalScrolling: true,

		// Set the global alignment offsets
		horizontalOffset: 0,
		verticalOffset: 0,

		// Refreshes parallax content on window load and resize
		responsive: true,

		// Select which property is used to calculate scroll.
		// Choose 'scroll', 'position', 'margin' or 'transform',
		// or write your own 'scrollProperty' plugin.
		scrollProperty: 'scroll',

		// Select which property is used to position elements.
		// Choose between 'position' or 'transform',
		// or write your own 'positionProperty' plugin.
		positionProperty: 'position',

		// Enable or disable the two types of parallax
		parallaxBackgrounds: true,
		parallaxElements: false,

		// Hide parallax elements that move outside the viewport
		hideDistantElements: true,

		// Customise how elements are shown and hidden
		hideElement: function($elem) { $elem.hide(); },
		showElement: function($elem) { $elem.show(); }
	});

});

$(document).on( "click", "a.friendpic", function (e) {
    e.preventDefault();
	$.ajax("/ajax-api/fetch-pic/"+$(this).data( "id" )).done(function(response) {
	  $('#friendsmodal').html(response).foundation('open');
	});
});

$(document).on( "submit", "#subscribeform", function (e) {
    e.preventDefault();
});


$(document).on('formvalid.zf.abide', function () {
	$.ajax({
		type: "POST",
		url: "/ajax-api/send-email/subscribe-form",
		data: $("#subscribeform").serialize(),
		cache: false,
		success: function(html) {
			console.log(html);
			$("#subscribeform .errorholder").html('<div class="success callout">Your subscription request been successfully recieved, thank you!</div>');
			$("#subscribeform .errorholder").show();
		},
		error: function(html) {
			console.log(html);
			$("#subscribeform .errorholder").html('<div class="alert callout">Your subscription request  has NOT been sent, please check / complete the required information and try again.</div>');
			$("#subscribeform .errorholder").show();
		}
	});    
});
